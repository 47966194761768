import React, { useEffect, useState } from "react";
import AASA from "../.well-known/apple-app-site-association";

const AppleAppSiteAssociation = () => {
  // when navigating to /apple-app-site-association get json file as application/json
  const [appleAppSiteAssociation, setAppleAppSiteAssociation] = useState(null);

  useEffect(() => {
    fetch(AASA)
      .then((response) => response.json())
      .then((data) => setAppleAppSiteAssociation(data));
  }, []);

  return (
    <pre className="blank">
      {JSON.stringify(appleAppSiteAssociation, null, 2)}
    </pre>
  );
};

export default AppleAppSiteAssociation;
