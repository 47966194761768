import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AppleAppSiteAssociation from "./components/AppleAppSiteAssociation";

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* add route to apple app site association */}
      {/* <Route
        path="/.well-known/apple-app-site-association"
        element={<AppleAppSiteAssociation />}
      /> */}
      {/* when routing to .well-known/apple-app-site-association get json file as application/json*/}
      <Route
        path="/apple-app-site-association"
        element={<AppleAppSiteAssociation />}
      />
    </Routes>
  </Router>
);

export default AppRoutes;
